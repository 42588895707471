export const SESSION_ID_COOKIE_KEY = 'sid'
export const PUBLIC_SESSION_ID_COOKIE_KEY = 'psid'
export const ANONYMOUS_SESSION_COOKIE_KEY = 'agsf_anonymous'
export const CUSTOMER_SESSION_COOKIE_KEY = 'agsf_customer'

export const ACCEPTED_ALL_KEY = 'cookieNoticedClosedAll'

export const MINUTE_IN_MS = 60000
export const HOUR_IN_MS = 3600000
export const DAY_IN_MS = HOUR_IN_MS * 24
export const SECONDS_IN_MINUTE = 60

export const MAX_ALLOWED_PRODUCT_IDS = 24
