/* eslint-disable indent */
import type { EcommerceApi } from 'shared/types/apiTypes'

const getRequestEndpointList = [
  'getProduct',
  'getTiles',
  'getSearchFacets',
  'getContent',
  'getContentAssets',
  'getContents',
  'getHierarchy'
]

export function useEcommerce() {
  const config = useRuntimeConfig()
  const locale = useNuxtApp().$i18n.locale

  const api = new Proxy(
    {},
    {
      get: (target, prop, receiver) => {
        const functionName = String(prop) as keyof EcommerceApi
        if (Reflect.has(target, functionName)) {
          return Reflect.get(target, prop, receiver)
        }
        return async (...args: any[]) => {
          const isGetRequest = getRequestEndpointList.includes(functionName)
          const requestUrl = new URL(
            (process.client ? config.public.bffBaseUrl : config.ssrBffBaseUrl) + `ecommerce/${functionName}`,
            process.client ? window.location.origin : undefined
          )

          requestUrl.searchParams.append('locale', locale.value ?? '')

          if (isGetRequest) {
            args.forEach((arg) => {
              requestUrl.searchParams.append('body', JSON.stringify(arg))
            })
          }

          const response = await $fetch<any>(requestUrl.toString(), {
            keepalive: true,
            credentials: 'include',
            body: isGetRequest ? undefined : JSON.stringify(args),
            method: isGetRequest ? 'GET' : 'POST'
          }).catch(async (err) => {
            if (err.statusCode === 440) {
              await useSession().expireSession()
            }
            return Promise.reject(err)
          })

          if (process.client && response && response.error) {
            const { __sidexp__: isSessionExpired, __maintenance__: isMaintenanceMode } = response.error
            if (isMaintenanceMode) {
              window.location.href = '/maintenance'
            }
            if (isSessionExpired) {
              EventBus.emit('sessionExpired')
            }
          }

          return response
        }
      }
    }
  )
  return api as EcommerceApi
}
